import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

const graphAuthRequest = {
  scopes: [`https://graph.microsoft.com/user.read`],
};

const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

const getMSGraphToken = async (
  instance: IPublicClientApplication,
  userAccount: any
) => {
  const accessTokenGraphRequest = { ...graphAuthRequest, account: userAccount };
  const token = await instance
    .acquireTokenSilent(accessTokenGraphRequest)
    .then((accessTokenGraphResponse) => accessTokenGraphResponse.accessToken);
  return token;
};

const getMSUserPhoto = async (accessTokenGraphToken: string) => {
  const userPhoto = await axios.get(
    graphConfig.graphMeEndpoint + '/photo/$value',
    {
      headers: { Authorization: `Bearer ${accessTokenGraphToken}` },
      responseType: 'blob',
    }
  );
  return userPhoto.data;
};

export function useMsalData() {
  const { instance, accounts } = useMsal();
  const name = accounts ? accounts[0].name : "";
  const email = accounts ? accounts[0].username : "";

  const [userAvatar, setUserAvatar] = useState('');
   useEffect(() => {
    async function getUserAvatar() {
      await getMSGraphToken(instance, accounts[0])
        .then((token) => getMSUserPhoto(token))
        .then((avatarURL) => URL.createObjectURL(avatarURL))
        .then((avatar) => setUserAvatar(avatar));
    }

    getUserAvatar();
    }, [instance, accounts]);

  return { name, email, userAvatar };
}
