import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {
  Button,
  Avatar,
  Typography,
  IconButton,
} from '@mui/material';
import { useMsal } from '@azure/msal-react';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

export const netlightAvatar = <Avatar alt="Home" src="/logo192.png" />

export const HomeButton = () => {
  return (
    <Link
      to={ROUTES.HOMEPAGE}
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginRight: 'auto',
      }}
      data-testid={'wylobHome'}
    >
      {netlightAvatar}
      <Typography style={{ color: 'white' }}> &nbsp; WYLOB</Typography>
    </Link>
  );
};

export function handleLogout(instance) {
  instance.logoutRedirect();
}

export const LogOutButton = () => {
  const { instance } = useMsal();

  return (
    <IconButton
      data-testid="logout"
      color="primary"
      style={{ color: 'white' }}
      onClick={() => handleLogout(instance)}
    >
      <LogoutRoundedIcon />
    </IconButton>
  );
};

export const LogOutListButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      data-testid="logout-bar"
      onClick={() => handleLogout(instance)}
      endIcon={<LogoutRoundedIcon />}
      size="large"
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      LOG OUT
    </Button>
  );
};
