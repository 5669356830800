import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import Header from './components/header/header';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import CircularProgress from '@mui/material/CircularProgress';

const HomePage = lazy(() => import('./pages/homePage'));
const NotFound = lazy(() => import('./pages/notFound'));
const RecommendationsPage = lazy(() => import('./pages/recommendationsPage'));
const EventsPage = lazy(() => import('./pages/eventsPage'));
const EventFormPage = lazy(() => import('./pages/eventFormPage'));
const DashboardPage = lazy(() => import('./pages/dashboardPage'));
const ManagementPage = lazy(() => import('./pages/managementPage'));

function App() {
  const [loading, setLoading] = useState(true);
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const accessTokenRequest = { ...loginRequest, account: accounts[0] };

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          const accessToken = accessTokenResponse.accessToken;
          localStorage.setItem('accessToken', accessToken);
          setLoading(false);
        })
        .catch(() => {
          instance.acquireTokenRedirect(accessTokenRequest);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [instance, inProgress, accounts]);

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <AuthenticatedTemplate>
        <Router>
          <Header />
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route path={ROUTES.HOMEPAGE} element={<HomePage />} />
              <Route
                path={ROUTES.RECOMMENDATIONS}
                element={<RecommendationsPage />}
              />
              <Route path={ROUTES.EVENTS} element={<EventsPage />} />
              <Route path={ROUTES.EVENTFORM} element={<EventFormPage />} />
              <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
              <Route path={ROUTES.MANAGEMENT} element={<ManagementPage />} />
              <Route element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <CircularProgress />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
