import { createTheme } from '@mui/material/styles';
// import { Theme } from '@mui/system'

const theme = createTheme({
  palette: {
    primary: {
      dark: '#9ca3db',
      main: '#727DD5',
      light: '#d8dbf0',
    },
    secondary: {
      main: '#FF7979',
    },
    tertiary: {
      main: '#E58995',
    },
    background: {
      default: '#1E1E1E',
    },
    typography: {
      fontFamily: 'proxima',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'proxima',
      color: '#333333',
    },
    h1: {
      fontSize: '54px',
      lineHeight: '64px',
    },
    h2: {
      fontSize: '44px',
      lineHeight: '54px',
    },
    h3: {
      fontSize: '32px',
      lineHeight: '42px',
      fontWeight: 600,
      letterSpacing: '0.1px',
    },
    h5: {
      fontSize: '20px',
      lineHeight: '26px',
    },
    h6: {
      fontSize: '18px',
      lineHeight: '26px',
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  shadows: Array(25).fill('none'),
});
export default theme;
