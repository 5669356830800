import { useState } from 'react';
import { LogOutListButton } from './buttons';

import {
  Avatar,
  Badge,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemText,
  Divider,
  ListItem,
  Button,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from 'styled-components';

export const DrawerComponent = (props: {
  name: string;
  isTS: boolean;
  userAvatar: any;
  email: string;
  buttonList: any[];
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const menuButton = (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="manu"
      onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      data-testid={'menuButton'}
    >
      <Avatar
        style={{
          border: '1px solid',
          color: 'white',
          background: 'transparent',
        }}
      >
        <MenuIcon />
      </Avatar>
    </IconButton>
  );

  const closeButton = (
    <IconButton
      aria-label="close"
      onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      color="primary"
      size="large"
      style={{ paddingRight: '0px' }}
    >
      <CloseRoundedIcon />
    </IconButton>
  );

  const personalInformation = (
    <ListItemText style={{ paddingRight: '8px' }}>
      <Box style={{ textAlign: 'right' }}>{props.name}</Box>
      <Box style={{ color: 'grey', textAlign: 'right' }}>{props.email}</Box>
    </ListItemText>
  );

  const drawer = (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      PaperProps={{
        sx: { width: '60%' },
      }}
    >
      <List>
        <StyledListItem>{closeButton}</StyledListItem>
        <StyledListItem>
          <div>
            <StyledBadge
              badgeContent={<StyledBadgeText>TS</StyledBadgeText>}
              invisible={!props.isTS}
              color="secondary"
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <StyledAvatar alt={props.name} src={props.userAvatar} />
            </StyledBadge>
          </div>
        </StyledListItem>
        <StyledListItem>{personalInformation}</StyledListItem>

        <Divider />
        {props.buttonList.map(
          (item) =>
            item.restrictedToTS && (
              <StyledListItem key={item.data_testid}>
                <Button
                  data-testid={item.data_testid}
                  href={item.href}
                  size="large"
                  endIcon={item.icon}
                >
                  {item.label}
                </Button>
              </StyledListItem>
            )
        )}

        <Divider />
        <StyledListItem>
          <LogOutListButton />
        </StyledListItem>
      </List>
    </Drawer>
  );

  return (
    <Box>
      {menuButton}
      {drawer}
    </Box>
  );
};

const StyledListItem = styled(ListItem)`
  align-items: flex-end;
  flex-direction: column;
`;

const StyledAvatar = styled(Avatar)`
  height: 64px;
  width: 64px;
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 24px;
    border-radius: 12px;
    bottom: 14%;
    right: 7%;
  }
`;

const StyledBadgeText = styled(Typography)`
  color: white;
  font-size: 10px;
  padding-top: 2px;
`;
