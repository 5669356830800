import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { useMsalData } from './useMsalData';
import { useIsTS } from '../../hooks/useUserController';

import { DrawerComponent } from './drawer';
import { LogOutButton, HomeButton } from './buttons';
import HideOnScroll from './scroller';

import {
  AppBar,
  Button,
  Toolbar,
  Container,
  Box,
  Avatar,
  Badge,
  Typography,
} from '@mui/material';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import KeyIcon from '@mui/icons-material/Key';

import * as ROUTES from '../../constants/routes';

import styled from 'styled-components';

export default function Header() {
  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate();

  const { name, email, userAvatar } = useMsalData();

  const isTS = useIsTS();
  const linkToRecommendationsText = isTS ? 'WYLOB list' : 'My WYLOBs';

  const buttonList = [
    {
      label: linkToRecommendationsText,
      href: ROUTES.RECOMMENDATIONS,
      data_testid: 'wylobList',
      icon: <ViewListRoundedIcon />,
      restrictedToTS: true,
    },
    {
      label: 'EVENTS',
      href: ROUTES.EVENTS,
      sub_routes: [ROUTES.EVENTFORM, ROUTES.DASHBOARD],
      data_testid: 'events',
      icon: <EmojiEventsIcon />,
      restrictedToTS: true,
    },
    {
      label: 'MANAGEMENT',
      href: ROUTES.MANAGEMENT,
      data_testid: 'management',
      icon: <KeyIcon />,
      restrictedToTS: isTS,
    },
  ];


  const linkIsActive = (item: { href: string; sub_routes?: string[] }) => {
    const associatedRoutes = [item.href, ...(item.sub_routes || [])];

    for (let i = 0; i < associatedRoutes.length; i++) {
      if (matchPath(associatedRoutes[i], currentPath)) return true;
    }

    return false;
  };

  return (
    <HideOnScroll>
      <AppBar
        style={{
          background: 'transparent',
          boxShadow: 'none',
          position: 'fixed',
        }}
        data-testid="appbar-header"
      >
        <Container maxWidth={false} style={{ margin: '0px' }}>
          <StyledToolbar color="transparent" disableGutters>
            <HomeButton />

            {/* Desktop Version */}
            <Box display={{ xs: 'none', md: 'inline-block' }}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  color: 'white',
                }}
              >
                {buttonList.map(
                  (item) =>
                    item.restrictedToTS && (
                      <StyledButton
                        disableRipple
                        className={linkIsActive(item) ? 'active' : ''}
                        data-testid={item.data_testid}
                        variant="text"
                        key={item.data_testid}
                        onClick={() => navigate(item.href)}
                        startIcon={item.icon}
                      >
                        {item.label}
                      </StyledButton>
                    )
                )}
                {name && (
                  <Badge
                    badgeContent={
                      <StyledBadgeText variant="body2">TS</StyledBadgeText>
                    }
                    invisible={!isTS}
                    color="secondary"
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    sx={{ marginLeft: '15px', marginRight: '15px' }}
                  >
                    <Avatar alt={name} src={userAvatar} />
                  </Badge>
                )}
                <LogOutButton />
              </Box>
            </Box>

            {/* Mobile Version */}
            <Box display={{ md: 'none' }}>
              {name && (
                <DrawerComponent
                  name={name}
                  isTS={isTS}
                  userAvatar={userAvatar}
                  email={email}
                  buttonList={buttonList}
                />
              )}
            </Box>
          </StyledToolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}

const StyledToolbar = styled(Toolbar)`
  margintop: 8px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding-right: 16px;
  padding-left: 16px;
  margin: 8px;
  color: white;
  border-radius: 0px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  &.active {
    border-bottom-color: white;
  }
`;

const StyledBadgeText = styled(Typography)`
  color: white;
  font-size: 10px;
`;
