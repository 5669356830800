import React from 'react';
import { useScrollTrigger, Slide } from '@mui/material/';

interface Props {
  children: React.ReactElement;
}

function HideOnScroll({ children }: Props) {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 40 });
  return (
    <Slide appear={false} direction={'down'} in={!trigger}>
      {children}
    </Slide>
  );
}

export default HideOnScroll;
