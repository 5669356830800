import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createTSUser,
  deleteTSUserById,
  findAllTSUser,
  getIsTS,
  updateTSUser,
} from '../api/endpoints';
import { TSUser } from '../types/types';

function useFindAllTSUserQuery() {
  return useQuery('findAllTSUser', findAllTSUser);
}

function useIsTS() {
  const query = useQuery('isTS', getIsTS);
  return query.data?.data;
}

function useCreateTSUser() {
  const queryClient = useQueryClient();
  return useMutation(
    'createTSUser',
    (createdTSUser: TSUser) => createTSUser(createdTSUser),
    {
      onSuccess: () => queryClient.invalidateQueries('findAllTSUser'),
    }
  );
}

function useUpdateTSUser(tsUserId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    'updateTSUser',
    (updatedTSUser: TSUser) => updateTSUser(tsUserId, updatedTSUser),
    {
      onSuccess: () => queryClient.invalidateQueries('findAllTSUser'),
    }
  );
}

function useDeleteTSUser() {
  const queryClient = useQueryClient();
  return useMutation(
    'deleteTSUserById',
    (tsUserId: string) => deleteTSUserById(tsUserId),
    {
      onSuccess: () => queryClient.invalidateQueries('findAllTSUser'),
    }
  );
}

export { useFindAllTSUserQuery, useIsTS, useCreateTSUser, useUpdateTSUser, useDeleteTSUser };
