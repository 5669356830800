import axios from 'axios';
import { msalConfig, loginRequest } from '../authConfig';
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

export const msalInstance = new PublicClientApplication(msalConfig);

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}`,
  headers: {
    'Content-type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const silentRequest = {
        ...loginRequest,
        account: msalInstance.getAllAccounts()[0],
      };
      try {
        const tokenResponse = await msalInstance.acquireTokenSilent(
          silentRequest
        );
        localStorage.setItem('accessToken', tokenResponse.accessToken);
        return axios(originalRequest);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(silentRequest);
        }
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
